<template>
  <audio
    ref="theAudio"
    autoplay="true"
    @playing="onPlaying"
    @ended="onEnded"
    @pause="onPause"
    @canplaythrough="onCanPlayThrough"
    @durationchange="onDurationChange"
    @timeupdate="onTimeUpdate"
    @error="onError"
  />
  <Card class="p-shadow-4 playerCard">
    <template #content>
      <section class="flex align-items-center gap-2 w-full" :aria-label="$t('aria_player_region')">

        <URLPlayButton
          class="p-button-rounded"
          :url="currentEntry?.url"
        />
        <Button
          icon="pi pi-angle-left"
          :disabled="!loaded"
          class="p-button-rounded"
          :aria-label="$t('aria_button_rewind')"
          @click="onBackwardButtonClick"
          accessKey=","
        />
  
        <Button
          icon="pi pi-angle-right"
          :disabled="!loaded"
          class="p-button-rounded"
          :aria-label="$t('aria_button_skip')"
          @click="onForewardButtonClick"
          accessKey="."
        />
  
        <template v-if="!compact">
          <template v-if="!currentEntry">{{ $t("label_no_audio") }}</template>
          <template v-else-if="!loaded">{{ $t("label_loading") }}</template>
          <template v-else>
            {{ positionText }}
            <Slider
              v-model="position"
              :max="duration"
              :disabled="!loaded || playing"
              class="flex-grow-1"
              @slideend="onSeek"
            />
            {{ durationText }}
          </template>
        </template>
      </section>
    </template>
  </Card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import URLPlayButton from "./URLPlayButton.vue";
import Card from "primevue/card";
import Slider from "primevue/slider";
import Button from "primevue/button";
import { getUrlForText, isUsefulString, truncateMiddle } from "../utils/Tools";
import { type PlayCallback, PlayCallbackReason, PlayEntry } from "../utils/OtherTypes";
import type { SpeechText } from "../utils/SpeechText";
import PlainText from "../utils/PlainText";
import { CacheLocation, InfoLevel, type CannedText } from "interactions/api";

import { SsmlMode } from "../utils/MultiText";
import { lang, Language, Voice } from "@tactonom/lang-js";
import { loadOpusAsCaf } from "@tactonom/opus2caf";

interface PlayerData {
  currentEntry: PlayEntry | undefined;
  queue: Array<PlayEntry>;
  duration: number;
  position: number;
  loaded: boolean;
  playing: boolean;
  previousEndEvent: Event | undefined;
}

function formattedTime(seconds: number): string {
  const onlySeconds = Math.floor(seconds % 60);
  const onlyMinutes = Math.floor(seconds / 60);
  let secondsString = "" + onlySeconds;
  if (secondsString.length == 1) {
    secondsString = "0" + secondsString;
  }
  return onlyMinutes + ":" + secondsString;
}

export default defineComponent({
  name: "Player",
  components: {
    URLPlayButton,
    Card,
    Slider,
    Button,
  },
  data(): PlayerData {
    return {
      currentEntry: undefined,
      queue: [],
      duration: 10,
      position: 0,
      loaded: false,
      playing: false,
      previousEndEvent: undefined,
    };
  },
  props: {
    enableOpus2Caf: Boolean,
    compact: Boolean,
  },
  mounted(): void {
    navigator.mediaSession.setActionHandler("play", () => {
      this.resume();
    });
    navigator.mediaSession.setActionHandler("pause", () => {
      this.pause();
    });
    navigator.mediaSession.setActionHandler("seekbackward", () => {
      this.onBackwardButtonClick();
    });
    navigator.mediaSession.setActionHandler("seekforward", () => {
      this.onForewardButtonClick();
    });
    // Most devices don't have seek-buttons, but skip-buttons.
    // Therefore, we link the skip-buttons to the seek-action as well.
    navigator.mediaSession.setActionHandler("previoustrack", () => {
      this.onBackwardButtonClick();
    });
    navigator.mediaSession.setActionHandler("nexttrack", () => {
      this.onForewardButtonClick();
    });
  },
  methods: {
    formattedTitle(entry: PlayEntry | null | undefined): string {
      if (!entry) {
        return this.$t("label_playback_no_entry");
      }
      return (
        truncateMiddle(entry.title, 140) ||
        this.$t("label_playback_no_title") + " " + entry.url
      );
    },
    async actuallyPlay(entry: PlayEntry) {
      this.currentEntry = entry;
      this.audio.pause();
      let source : string = entry.url;
      this.loaded = false;
      this.position = 0;
      const meta = {
        title: this.formattedTitle(entry),
        artist: "ProBlind Share",
        album: this.globalProps.document?.rootArea!.titleDisplayTextString,
        artwork: [],
      };
      navigator.mediaSession.metadata = new window.MediaMetadata(meta);

      if (this.needsClientSideConversion) {
        source = URL.createObjectURL(await loadOpusAsCaf(entry.url));
      }

      this.audio.src = source;
    },
    async playUrl(
      url: string,
      enqueue: boolean,
      callback?: PlayCallback,
      title?: string
    ): Promise<void> {
      //console.trace();
      const newEntry = new PlayEntry(url, callback, title);
      if (!enqueue) {
        if (this.currentEntry) {
          this.currentEntry.execCallback(PlayCallbackReason.INTERRUPTED);
          this.currentEntry = undefined;
        }
        for (const entry of this.queue) {
          entry.execCallback(PlayCallbackReason.INTERRUPTED);
        }
        //console.log("Emptied queue of " + this.queue.length + " because enqueue == false.");
        this.queue = [];
      }
      if (this.currentEntry) {
        this.queue.push(newEntry);
        //console.log("Added to queue of " + this.queue.length + " (incl. this) because something is currently playing.");
      } else {
        //console.log("Played directly (ignoring queue of " + this.queue.length + ") because nothing currently playing.");
        await this.actuallyPlay(newEntry);
      }
    },
    playCannedText(
      canned: string, //this is a json string that represents the canned text item
      enqueue: boolean,
      graphicId?: string //this is only needed for AREA and GRAPHIC CacheLocations
    ): void {
      const cannedText: CannedText = JSON.parse(canned);

      switch (cannedText.cacheLocation) {
        case CacheLocation.AREA:
          if (graphicId === undefined) {
            console.error("playCannedText: graphicId missing for AREA"); //TODO: play a beep
          } else {
            const url =
              import.meta.env.VITE_SVG_REPO_BASE_URL +
              "/ctrl/bundle/api/" +
              graphicId +
              "/audio/generated/" +
              cannedText.audioFilename + '.opus';
            this.playUrl(url, enqueue, undefined, cannedText.plainText);
          }
          break;
        case CacheLocation.GRAPHIC:
          if (
            graphicId === undefined ||
            /*null === cannedText.audioFilename() ||*/ undefined ===
              cannedText.language
          ) {
            console.error(
              "playCannedText: graphicId or audioFilename missing for GRAPHIC"
            ); //TODO: play a beep
          } else {
            //until we have proper support for pre-generating canned text for a graphic:
            //this.playText(new PlainText(cannedText.plainText), enqueue, cannedText.language, null, undefined );
            const url =
              import.meta.env.VITE_SVG_REPO_BASE_URL +
              "/ctrl/bundle/api/" +
              graphicId +
              "/audio/" +
              cannedText.voiceId +
              "/" +
              cannedText.audioFilename +
              ".opus";
            this.playUrl(url, enqueue, undefined, cannedText.plainText);
          }
          break;
        case CacheLocation.NUMBER:
          if (undefined === cannedText.language) {
            console.error("playCannedText: language missing for NUMBER");
            //TODO: play a beep
          } else {
            const url =
              import.meta.env.VITE_SVG_REPO_BASE_URL +
              "/speech/system/" +
              cannedText.language +
              "/" +
              cannedText.audioFilename +
              ".opus";
            this.playUrl(url, enqueue, undefined, cannedText.plainText);
            //this.playText(new PlainText(cannedText.plainText), enqueue, cannedText.language, null, undefined );
          }
          break;
        case CacheLocation.ENGINE:
          if (
            /*null === cannedText.audioFilename() ||*/ undefined ===
            cannedText.language
          ) {
            console.error(
              "playCannedText: audioFilename or language missing for GRAPHIC"
            ); //TODO: play a beep
          } else {
            //until we have proper support for pre-generating canned text for a graphic:
            this.playText(
              new PlainText(cannedText.plainText),
              enqueue,
              lang.getLanguageByTag(cannedText.language)!,
              undefined,
              undefined
            );
          }
          break;
        case CacheLocation.SYSTEM: {
          const url =
            import.meta.env.VITE_SVG_REPO_BASE_URL +
            "/speech/system/" +
            (cannedText.language === undefined
              ? "en-US"
              : cannedText.language) +
            "/" +
            cannedText.audioFilename +
            ".opus";
          this.playUrl(url, enqueue, undefined, cannedText.plainText);
          break;
        }
        case CacheLocation.ENGLISH:
          console.error("not implemented: playCannedText.ENGLISH");
          break;
      }
    },
    playText(
      text: SpeechText,
      enqueue: boolean,
      lang: Language,
      voice: Voice | undefined,
      callback: PlayCallback | undefined
    ): void {
      if (text.hasContent()) {
        const url = getUrlForText(text, lang, voice, this.needsServerSideConversion);
        if (url) {
          this.playUrl(url, enqueue, callback, text.value || "(empty)");
        }
      }
    },
    pause(): void {
      if (this.playing) {
        this.audio.pause();
      }
    },
    resume(): void {
      if (!this.playing) {
        this.audio.play();
      }
    },
    //TODO: rework player to handle area playback messages
    speakArea(
      areaId: string,
      infoLevel: InfoLevel,
      enqueue: boolean,
      playAreasFromBundle: boolean,
    ): void {
      //TODO: support the multiple ways of rendering title and description which we currently have...
      if (!isUsefulString(areaId)) {
        //throw "areaId missing.";
        this.playText(
          new PlainText("Area I D missing."),
          enqueue,
          lang.getEnglish(),
          undefined,
          undefined
        );
        return;
      }
      const area = this.globalProps.document?.getAreaById(areaId);
      if (!area) {
        //throw "areaId missing.";
        this.playText(
          new PlainText("Area not found: " + areaId),
          enqueue,
          lang.getEnglish(),
          undefined,
          undefined
        );
        return;
      }
      if (infoLevel == InfoLevel.TITLE || this.globalProps.scriptRunning) {
        area.title.play(this, SsmlMode.AUTO, enqueue, playAreasFromBundle);
      }
      if (infoLevel == InfoLevel.DESCRIPTION) {
        area.description.play(this, SsmlMode.AUTO, enqueue, playAreasFromBundle);
      }
      if (infoLevel == InfoLevel.TITLE_AND_DESCRIPTION) {
        area.title.play(this, SsmlMode.AUTO, enqueue, playAreasFromBundle);
        area.description.play(this, SsmlMode.AUTO, true, playAreasFromBundle);
      }
    },
    onPlaying(): void {
      this.loaded = true;
      this.playing = true;
      navigator.mediaSession.playbackState = "playing";
    },
    async onError(event: Event): Promise<void> {
      const playErrorText = this.$t("error_play");
      if (this.currentEntry?.title?.startsWith(playErrorText)) {
        console.error("Error while playing error message. Aborting.");
        await this.onEnded(event);
      }
      const text = playErrorText + ": " + this.currentEntry?.title;
      const url = getUrlForText(new PlainText(text), this.globalProps.uiLang, undefined, this.needsServerSideConversion);
      if (url && this.currentEntry?.title && !this.currentEntry?.title?.startsWith(playErrorText)) {
        console.error("Sound playback failed for '" + this.currentEntry?.title + "', speak an error message instead...");
        const errorEntry = new PlayEntry(url, undefined, text);
        await this.actuallyPlay(errorEntry);
      } else {
        console.error("Sound playback failed for '" + this.currentEntry?.title + "', speaking an error message also failed, just continue...");
        await this.onEnded(event);
      }
    },
    async onEnded(event: Event): Promise<void> {
      // we have to filter out duplicate calls of onEnded with similar events 
      // which actually reference the same media that has ended.
      if (this.previousEndEvent) {
        const timeSpan = Math.abs(event.timeStamp - this.previousEndEvent.timeStamp);
        if (timeSpan < 50 /* milliseconds */) {
          //console.log("Detected two onEnded events which probably affect the same media. Ignored the second one.");
          this.previousEndEvent = event;
          return;
        }
      }

      this.previousEndEvent = event;

      if (this.currentEntry) {
        //console.log("Finished playing " + this.formattedTitle(this.currentEntry), event);
        this.currentEntry.execCallback(PlayCallbackReason.FINISHED);
        this.currentEntry = undefined;
      } else {
        //console.log("Finished playing something.");
      }

      if (this.queue.length > 0) {
        await this.actuallyPlay(this.queue.shift()!);
      } else {
        this.playing = false;
      }
      navigator.mediaSession.playbackState = "none";
    },
    async onPause(event: Event): Promise<void> {
      this.playing = false;
      if (this.currentEntry) {
        // Sometimes, the browser will call onPause **instead** of onEnded when the audio has actually ended.
        // But sometimes, it will call **both** methods. If this onPause seems like it could be caused by
        // the media ending, we call onEnded. Then, onEnded has to filter out duplicate calls.

        // also, we cannot simply compare floating point numbers for equality
        if (Math.abs(this.audio.currentTime - this.audio.duration) < 0.05) {
          await this.onEnded(event);
        } else {
          this.currentEntry.execCallback(PlayCallbackReason.PAUSED);
          navigator.mediaSession.playbackState = "paused";
        }
      }
    },
    onCanPlayThrough(): void {
      this.loaded = true;
      this.duration = this.audio.duration;
    },
    onDurationChange(): void {
      this.duration = this.audio.duration;
    },
    onTimeUpdate(): void {
      this.position = this.audio.currentTime;
    },
    onBackwardButtonClick(): void {
      if (this.loaded) {
        this.audio.currentTime -= 1;
      }
    },
    onForewardButtonClick(): void {
      if (this.loaded) {
        this.audio.currentTime += 1;
      }
    },
    onSeek(): void {
      if (!this.playing) {
        this.audio.currentTime = this.position;
      }
    },
  },
  computed: {
    audio(): HTMLMediaElement {
      return this.$refs.theAudio as HTMLMediaElement;
    },
    hasOpusSupport(): boolean {
      return (
        this.audio != null &&
        this.audio.canPlayType("audio/ogg;codecs=opus") !== ""
      );
    },
    hasCafSupport(): boolean {
      return (
        this.audio != null &&
        this.audio.canPlayType("audio/x-caf") !== ""
      );
    },
    needsServerSideConversion(): boolean {
      return !this.hasOpusSupport && (!this.hasCafSupport || !this.enableOpus2Caf);
    },
    needsClientSideConversion(): boolean {
      return !this.hasOpusSupport && this.hasCafSupport && this.enableOpus2Caf;
    },
    positionText(): string {
      return formattedTime(this.position);
    },
    durationText(): string {
      return formattedTime(this.duration);
    },
    truncatedUrl(): string {
      return truncateMiddle(this.currentEntry?.url, 80) || "(none)";
    },
  },
});
</script>

<style>
.bottomOverlay .p-card-content {
  display: flex;
  align-items: center;
  padding: 0 0 !important;
}

.bottomOverlay .p-card-body {
  padding: 0.5rem !important;
}

.bottomOverlay .p-slider {
  flex-grow: 1;
}
</style>
