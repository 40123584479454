<template>
  <Dialog v-model:visible="visible" :modal="true" :header="$t('heading_login')" :style="{ width: '50vw' }" :closable="false">
    <form id="login">
      <div v-if="loginReason" class="mb-4"><b>{{ loginReason }}</b></div>
      <div class="p-fluid grid formgrid">
        <div class="field col-12">
          <!-- This is the email, but password-managers work better when it's named username -->
          <label for="username">{{ $t("label_email_address") }}</label>
          <InputText id="username" name="username" autocomplete="username" required="true" v-model="email" />
          <small>{{ validation.emailWarning }}</small>
        </div>
        <div class="field col-12">
          <label for="password">{{ $t("label_password") }}</label>
          <Password
            v-model="password"
            :pt="{
                input: {
                  id:'password',
                  name:'password',
                  autocomplete:'current-password',
                  required:'true'
                }
              }"
            :feedback="false"
            toggleMask />
        </div>
      </div>
      <Message v-if="loginFailed">{{
        $t("label_email_address_detail_login")
      }}</Message>
    </form>
    <a :href="'import.meta.env.BASE_URL' + 'app/resetPasswordRequest'" @click="resetPasswordRequest">{{ $t("link_forgot_password") }}</a>
    <template #footer>
      <div class="flex align-items-center">
        <Button 
          :label="$t('button_register')"
          icon="pi pi-user-plus"
          :disabled="$router.currentRoute.value.name=='register'"
          @click="register" 
        />
        <span style="flex: 1" />
        <Button 
          :label="$t('button_cancel')"
          icon="pi pi-times"
          autofocus
          @click="cancel();" 
        />
        <Button
          :label="$t('button_login')"
          icon="pi pi-sign-in"
          :loading="loading"
          :disabled="loading || !validation.emailAllowed || password?.length < 8 || loading"
          @click="login"
        />
      </div>
    </template>
  </Dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Button from "primevue/button";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import { DeferredPromise } from '@ablestack/deferred-promise-ts';

import { emailValidation, lastElement, templateToast } from "../../utils/Tools";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import { parseErrorMessageEx } from "../../utils/Error";
import type { TranslationKey } from "../../lib/translations";

interface LoginData {
  email: string;
  password: string;
  loading: boolean;
  loginFailed: boolean;
  visible: boolean;
  loginReason: string | undefined;
  currentPromise: DeferredPromise<boolean> | undefined;
}

export default defineComponent({
  name: "LoginDialog",
  components: {
    Button,
    Password,
    InputText,
    Toast,
    Message,
    Dialog,
  },
  data(): LoginData {
    return {
      email: "",
      password: "",
      loading: false,
      loginFailed: false,
      visible: false,
      loginReason: undefined,
      currentPromise: undefined,
    };
  },
  props: {
    showToasts: {
      type: Boolean,
      required: false
    },
  },
  emits: {
    loginSuccess() {
      return true;
    },
    loginFailure(_payload: { errorMessage: string }) {
      return true;
    },
    register() {
      return true;
    },
  },
  methods: {
    async show(loginReason?: string): Promise<boolean> {
      this.loginReason = loginReason;
      this.visible = true;
      this.currentPromise = new DeferredPromise();
      return this.currentPromise;
    },
    cancel(): void {
      this.hide();
      this.currentPromise?.resolve(false);
    },
    hide(): void {
      this.visible = false;
    },
    async login() {
      this.loading = true;
      const body = new URLSearchParams();
      body.append("username", this.email.trim());
      body.append("password", this.password.trim());

      const result = await fetch(import.meta.env.VITE_SVG_REPO_BASE_URL + "/ctrl/auth/api/login", {
        method: "POST",
        body,
        credentials: "include",
      });
      this.loading = false;

      if (result.redirected) {
        // Micronaut reacts to login-requests with a redirect.
        // This could be disabled by micronaut.security.redirect.enabled = false,
        // but then Micronaut will always respond with HTTP 200 and an empty response,
        // so it is impossible to distinguish success and failure.
         // And since a while, our own APIs also respond with redirects to /app/message/{translation_key}
        // if the request URL does not contain `/api/`, because the response might be viewed by the user.
        if (result.url.endsWith("authFailed")) {
          this.loginFailed = true;
          if (this.showToasts) {
            templateToast("login", "error");
          }
        } else if (result.url.endsWith("authSuccess")) {
          this.loginFailed = false;
          if (this.showToasts) {
            templateToast("login", "success");
          }
          this.globalProps.accountInfo!.isLoggedIn = true;
          this.hide();
          this.currentPromise?.resolve(true);
          // TODO if a document is loaded, its metadata must be reloaded, so that
          // the new user rights apply
        } else if (result.url.includes("/app/message/")) {
          let translationKey = lastElement(result.url.split("/"));
          if (this.showToasts) {
            this.$toast.add({
              severity: "error",
              summary: this.$t("toast_login_error_header"),
              detail: this.$t(translationKey as TranslationKey),
              group: "bc",
            });
          }
        } else {
          templateToast("login_unknown", "error");
        }
      } else if (result.status == 404 && result.url.includes(":5174")) {
        // This should not happen in production, but often happens with local testing 
        // because the Vite server would need to proxy POST requests but not GET requests.
        // This cannot be configured in node-http-proxy.
        // We are using a specific login URL in development to prevent this, but if anything goes
        // wrong with that, we display a helpful message anyway:
        templateToast("login_dev_server", "error");
        return;
      } else {
        let structuredResponse = await parseErrorMessageEx(result);
        this.$toast.add({
          severity: "error",
          summary: this.$t("toast_login_error_header"),
          detail: structuredResponse.message,
          group: "bc",
        });
      }
    },
    register() {
      this.hide();
      this.currentPromise?.resolve(false);
      this.$router.push({ name: "register", state: {email: this.email?.trim(), password: this.password?.trim()} });
    },
    resetPasswordRequest(event: Event) {
      this.hide();
      this.currentPromise?.resolve(false);
      this.$router.push({ name: "reset_password_request", state: {email: this.email} });
      event.preventDefault();
    },
  },
  computed: {
    validation(): any {
      return emailValidation(this.email);
    },
  },
});
</script>
